import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-listings',
  templateUrl: './job-listings.component.html',
  styleUrls: ['./job-listings.component.scss']
})
export class JobListingsComponent implements OnInit {
  @Input() public titleHeader: string = 'Jobs';
  @Input() public presentationHeader: string = "If you're passionate about making great engaging creative apps, you're welcome to send reach out or send us a CV: <a href='mailto:hiring@pivotal-solutions.co.il' class='item-color'>hiring@pivotal-solutions.co.il</a> Below are a list of currently available jobs. (All jobs are remote work at the moment.)";
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goTo() {
    this.router.navigate(['/home'])
  }

}
