import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-out-sourcing',
  templateUrl: './out-sourcing.component.html',
  styleUrls: ['./out-sourcing.component.scss']
})
export class OutSourcingComponent implements OnInit {
  @Input() public titleHeader: string = 'We can help your dev team bulk up';
  @Input() public presentationHeader: string = "So you have your own development team and they're awesome, but you're missing some front-end expertise, or you want to bring in reinforcements to get over the finish line with your tight time constraints. We can help.";
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
