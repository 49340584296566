import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-first-aid',
  templateUrl: './first-aid.component.html',
  styleUrls: ['./first-aid.component.scss']
})
export class FirstAidComponent implements OnInit {

  @Input() public titleHeader: string = 'We bring the magic';
  @Input() public presentationHeader: string = "We want to make apps that <span class='item-color'>excite</span> and <span class='item-color'>fascinate</span>. Do you have an App and wish it excited and fascinated your users? That's where we can come in and make the magic happen for your users too.";
  
  constructor() { }

  ngOnInit(): void {
  }

}
