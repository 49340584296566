<app-header [titleHeader]="titleHeader" [presentationHeader]="presentationHeader"></app-header>
<section class="contact-container">
    <form class="contact-form container" #userContact="ngForm" (ngSubmit)="onClickSubmit(userContact.value)">
        <div *ngIf="displayErrorMessage" class="error-message">
            Your message couldn't be sent.
        </div>
        <section class="contact-info">
            <div class="form-group first-group">
                <input type="text" class="first-name" placeholder="First Name" name="fname" ngModel>
            </div>
            <div class="form-group last-group">
                <input type="text" class="last-name" placeholder="Last Name" name="lname" ngModel>
            </div>
        </section>
        <section class="contact-info">
            <div class="form-group first-group">
                <input type="text" class="last-name" placeholder="Phone Number" name="phone" ngModel>
            </div>
            <div class="form-group last-group">
                <input type="text" class="last-name" placeholder="Email" name="email" ngModel>
            </div>
        </section>
        <section class="form-group">
            <textarea cols="30" rows="10" placeholder="Message" name="message" ngModel></textarea>
        </section>
        <section class="form-group submit-btn-container">
            <input type="submit" class="submit-btn" value="Submit">
        </section>
    </form>
    <svg class="svg-separator separator-up curveDown curveDownMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
    </svg>
</section>
<app-footer></app-footer>