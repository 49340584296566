<footer class="footer-site">
    <section class="container global-footer-presentation">
        <hr class="separation-line">
        <h2 class="thanks">More experience building professional apps than you can shake a stick at<span class="item-color">.</span></h2>
        <p>Our highly trained developers come from a wide range of backgrounds and experience to cover the latest technological advances and put them to work for our clients. We can be your entire dev team, if you're bootstrapping a startup and want to release an MVP. We can complement your existing development team with front-end expertise if that is something you're lacking, or we can take over an existing unsupported project and bring it back to life.
        </p>
        <div class="short-about">
            <div class="explanations">
                <span class="read-more">Pivotal Solutions Ltd.</span> was created in late 2012, it started as a small web and
              mobile app development consultancy and we're still growing. Millions of people have benefited from our deployed systems around the world. We've done work on four continents, and in most major business sectors. We've saved billions of kilograms of carbon pollution, and we haven't even been around for a decade. <span class="item-color">We want to be a part of your future.</span>
            </div>
            <div class="logo">
                <img src="../../assets/img/ps_logo.png" alt="Website Logo" width="300px">
            </div>
        </div>
    </section>
</footer>
