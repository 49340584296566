import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  @Input() public titleHeader: string = 'Contact';
  @Input() public presentationHeader: string = "Ask us anything. We're always ready to listen.";
  public displayErrorMessage: boolean = false;
  
  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {
    
  }

  onClickSubmit(data) {
    console.log(data)
    this.http.post('https://yitz.com/passthru.php', data)
    .subscribe((data) => {
      console.log('data from back end ', data);
      this.redirectUser();
    }, (error) => {
      console.log('error from back end ', error)
      this.displayErrorMessage = true
    })
  }

  redirectUser() {
    this.router.navigate(['/contact-successful']);
  }
}
