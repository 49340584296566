<app-header [titleHeader]="titleHeader" [presentationHeader]="presentationHeader">
</app-header>

<section class="first-aid-container global-article-presentation">
    <section class="container">
        <article class="column-article-presentation">
            <div class="icon-section">
                <div class="icon">
                    <i class="fa fa-2x fa-lightbulb fas"></i>
                </div>
                <div class="title-section">Ideaify</div>
                <div class="underline"></div>
            </div>
            <div class="content-section">
                Sometimes it's not what you say, but how you say it. A really engaging user experience makes the user's
                next
                step obvious. The App just <i>makes sense</i>.
            </div>
        </article>
        <article class="column-article-presentation">
            <div class="content-section">
                Not every App, should be exciting. But every app should definitely be rewarding. If your users don't
                come away
                feeling like their time was well-spent, then chances are they may not come back at all. We build
                experience into
                your app such that users are stimulated and rewarded just by using the interface, they will want to keep
                playing
                around, even if they're just doing their taxes or comparing insurance prices.
            </div>
            <div class="icon-section">
                <div class="icon">
                    <i class="fa-2x fa-chess-knight fas"></i>
                </div>
                <div class="title-section">Gamify</div>
                <div class="underline"></div>
            </div>
        </article>
        <article class="column-article-presentation">
            <div class="icon-section">
                <div class="icon">
                    <i class="fa fa-2x fa-bolt fas"></i>
                </div>
                <div class="title-section">Electrify</div>
                <div class="underline"></div>
            </div>
            <div class="content-section">
                Every app has parts that are essential yet boring. Nobody (well, almost nobody) cares if your about
                screen is
                cool. More importantly, there are parts of your app that millions of people will see and use
                all..the..time.
                That's where you want to add the refinement, the speed, the velvety smoothness, the lightening. Our job
                is to
                find those spots where a little tweak, a little twist will make your users grin, or sigh with a
                resounding
                "finally!" We want your users to feel like your app gets them.
            </div>
        </article>
        <article class="column-article-presentation">
            <div class="content-section">
                A little sparkle, a gleam of color never hurt anyone.. unless it's a really annoying cutesy animation
                that you
                have to wait for every...single...time. That's the difference between some glamour and what we do. We
                add the
                pop where it counts, but your apps still feel snappy in all the right places.
            </div>
            <div class="icon-section">
                <div class="icon">
                    <i class="fa-2x fa-hand-sparkles fas"></i>
                </div>
                <div class="title-section">Popify</div>
                <div class="underline"></div>
            </div>
        </article>
    </section>
    <svg class="svg-separator separator-up curveDown curveDownMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
    </svg>
</section>
<app-footer></app-footer>