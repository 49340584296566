<app-header [titleHeader]="titleHeader" [presentationHeader]="presentationHeader"></app-header>
<section class="mvp-container global-article-presentation">
    <section class="container">
        <article class="column-article-presentation">
            <div class="icon-section">
                <div class="icon">
                    <i class="fa fa-2x fa-dna fas"></i>
                </div>
                <div class="title-section">Concepting</div>
                <div class="underline"></div>
            </div>
            <div class="content-section">
                Concepting is where we delve deep on the basic idea and examine all kinds of possibilities before
                choosing a general direction to advance with some basic guiding principles.
            </div>
        </article>
        <article class="column-article-presentation">
            <div class="content-section">
                Then we take the general direction, and guiding principles and run through all the different
                permutations of how your App could work. We're done with prototyping when we feel like we've got a good
                fit between the idea and the flow of how things will work.
            </div>
            <div class="icon-section">
                <div class="icon">
                    <i class="fa-2x fa-drafting-compass fas"></i>
                </div>
                <div class="title-section">Rapid Prototyping</div>
                <div class="underline"></div>
            </div>
        </article>
        <article class="column-article-presentation">
            <div class="icon-section">
                <div class="icon">
                    <i class="fa fa-2x fa-paper-plane fas"></i>
                </div>
                <div class="title-section">Proof of Concept</div>
                <div class="underline"></div>
            </div>
            <div class="content-section">
                To test out the whole system, we build a functional app where the most central flows are actually and
                truly functional, to make sure the App is accomplishing what we care about most.
            </div>
        </article>
        <article class="column-article-presentation">
            <div class="content-section">
                Polishing the App involves adding in all the ancillary functionality, the profile edits, the forgot
                passwords, the onboarding, the gamification, taking the app from a great functional idea to something
                users will enjoy.
            </div>
            <div class="icon-section">
                <div class="icon">
                    <i class="fa-2x fa-fingerprint fas"></i>
                </div>
                <div class="title-section">Polish</div>
                <div class="underline"></div>
            </div>
        </article>
        <article class="column-article-presentation">
            <div class="icon-section">
                <div class="icon">
                    <i class="fa fa-2x fa-fighter-jet fas"></i>
                </div>
                <div class="title-section">MVP</div>
                <div class="underline"></div>
            </div>
            <div class="content-section">
                This is launch. We get the App out and then get good analytics to react to real world responses,
                tweaking where necessary to improve user uptake and market fit.
            </div>
        </article>
    </section>
    <svg class="svg-separator separator-up curveDown curveDownMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
    </svg>
</section>
<app-footer></app-footer>