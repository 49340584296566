<app-header [titleHeader]="titleHeader" [presentationHeader]="presentationHeader"></app-header>

<section class="job-listings-container global-article-presentation">
    <section class="container">
        <blockquote class="job">
            <div class="job-title">
                <div class="title">Junior Front-End Web Developer</div>
                <div class="border-separation"></div>
                <div></div>
            </div>
            <p class="presentation">
                If you like making awesome UI and love SCSS, then you're great for the job (provided you have some
                knowledge of
                either React, Angular, or Vue). Most of all, we want someone who can think on their feet and wants to
                soak up
                all the knowledge and experience we have to offer.
            </p>
        </blockquote>
        <blockquote class="job">
            <div class="job-title">
                <div class="title">Junior Full-Stack Web Developer</div>
                <div class="border-separation"></div>
                <div></div>
            </div>
            <p class="presentation">
                Full-Stack is always a big ask. Everyone says they're Full-Stack, but what stack exactly? We lean
                towards MEAN,
                but still work with LAMP too.. and are always open to discovering better and newer stacks as long as
                they're
                production-tested -- come help us to see the light, and we'll help you to grow your ambitions and
                experience. It
                helps to have serious Node.js chops and good experience in either MySQL or Mongo.. but we care more
                about what
                you can learn and how than what you've learned thus far.
            </p>
        </blockquote>
        <blockquote class="job">
            <div class="job-title">
                <div class="title">Interaction Designer</div>
                <div class="border-separation"></div>
                <div></div>
            </div>
            <p class="presentation">
                Show us a portfolio. Talk us up on some big ideas.
            </p>
        </blockquote>

        <blockquote class="job">
            <div class="job-title">
                <div class="title">DevOps Lead</div>
                <div class="border-separation"></div>
                <div></div>
            </div>
            <p class="presentation">
                We maintain a lot of different kinds of apps, web and mobile, for a number of different clients. With an
                equal
                number of server architectures. If you like challenges we'd love to have you. You will get to help
                design and
                plan future deployment architectures as well as wrange the existing ones into line.
            </p>
        </blockquote>


        <input type="submit" class="home-btn" value="Home" (click)="goTo()">
    </section>
    <svg class="svg-separator separator-up curveDown curveDownMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
    </svg>
</section>
<app-footer></app-footer>