import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-successful',
  templateUrl: './contact-successful.component.html',
  styleUrls: ['./contact-successful.component.scss']
})
export class ContactSuccessfulComponent implements OnInit {
  @Input() public titleHeader: string = 'Contact Successful';
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goTo() {
    this.router.navigate(['/home'])
  }

}
