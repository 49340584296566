<section class="home-container go-down">
    <section class="container">
        <header class="header">
            <h1>Welcome to Pivotal Solutions Ltd<span class="item-color">.</span></h1>
            <div class="border-separation"></div>
            <div class="under-border-separation"></div>
            <section class="proposition">
                <article class="box-proposition">
                    <div class="title">Need Reinforcements?</div>
                    <div class="underline"></div>
                    <p>We can step up and supply the high quality developers your team needs to complete the mission.</p>
                    <div>
                        <span class="read-more" [routerLink]="['/out-sourcing']">Read More</span>
                    </div>
                </article>
                <article class="box-proposition">
                  <div class="title">Want to build an App?</div>
                  <div class="underline"></div>
                  <p>We've been helping people and companies translate their ideas into Apps for more than 10 years.
                  </p>
                  <div>
                    <span class="read-more" [routerLink]="['/mvp']">Read More</span>
                  </div>
                </article>
                <article class="box-proposition">
                    <div class="title">Ready to spice things up?</div>
                    <div class="underline"></div>
                    <p>We take apps on life support and revive them with magical user experiences where they're needed most.</p>
                    <div>
                        <span class="read-more" [routerLink]="['/first-aid']">Read More</span>
                    </div>
                </article>
            </section>
        </header>
    </section>
    <svg class="svg-separator separator-down curveUp curveUpMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 100 C 20 0 50 0 100 100 Z"></path>
    </svg>
</section>

<section class="time-to-start">
    <h2>Apps are a dime a dozen.</h2>
  <div>BUT WE can make YOUR APP the one <i>users keep coming back to</i> AGAIN and AGAIN.</div>
    <a class="transparent-button" [routerLink]="['/contact']">I'm Interested</a>
    <svg class="svg-separator separator-up curveDown curveDownMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
    </svg>
</section>

<section class="global-block-division">
    <div class="block-division-presentation">
        <figure class="article-card">
            <figcaption>
                <h2 class="article-title">
                    <a class="article-title-link">
                        Apps that are vital<span class="item-color">,</span> Apps that scale<span class="item-color">,</span> Apps that delight<span class="item-color">.</span>
                    </a>
                </h2>
                <p class="article-content">
                  We build apps that enable complex custom workflows. Apps that simplify problems like: Generation and routing of medical and dental referrals, Life insurance fast-track application processes, health and diet logging and analysis, world-wide cross-network vpn configuration, estate management inheritance and conservatorship, or air traffic controller and pilot communications.
                </p>
            </figcaption>
        </figure>
    </div>
    <svg class="svg-separator separator-down curveUp curveUpMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 100 C 20 0 50 0 100 100 Z"></path>
    </svg>
</section>

<section class="offer">
    <h2>We’re a <span class="item-color">genius</span> user experience <br> and fluid workflow <span
            class="item-color">development agency</span>.</h2>
    <div class="articles-container">
        <article>
            <i class="fa fa-cog"></i>
            <div class="title">Rock-solid systems</div>
            <p>Simple, robust and forward-thinking.</p>
        </article>
        <article>
          <i class="fa fa-drafting-compass"></i>
          <div class="title">UX / Gamification</div>
          <p>Make addictively delightful apps.</p>
        </article>
        <article>
            <i class="fa fa-brain"></i>
            <div class="title">Unique Solutions</div>
            <p>Challenge us. Throw a problem at us no one else can solve. We will surprise you.</p>
        </article>
    </div>
    <svg class="svg-separator separator-up curveDown curveDownMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
    </svg>
</section>

<section class="why go-down">
    <section class="container">
        <section class="title">
            <h2>You want us in your corner.</h2>
        </section>
        <article class="explanation">
            <p>We know how to listen. Listen to what you want and understand what you need. We know how to adapt those needs to a workflow and interfaces that make sense to your users, and to bring it all together in a cohesive solution to your web or mobile application needs.</p>

            <p>In each area or industry, we work to build reliable, performant and scalable web and mobile-based applications. We develop and leverage expertise in whatever you need and build our apps with the flexibility to pivot and adapt as your competitive landscape evolves.</p>
            <div class="black-button-container">
                <a class="black-button" [routerLink]="['/contact']">I'm Interested &nbsp; <i class="fa fa-chevron-right"></i></a>
            </div>
        </article>
    </section>
    <svg class="svg-separator separator-up curveDown curveDownMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
    </svg>
</section>

<app-footer></app-footer>
