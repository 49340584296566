import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { OutSourcingComponent } from './out-sourcing/out-sourcing.component';
import { FirstAidComponent } from './first-aid/first-aid.component';
import { MvpComponent } from './mvp/mvp.component';
import { ContactComponent } from './contact/contact.component';
import { HeaderComponent } from './header/header.component';
import { ContactSuccessfulComponent } from './contact-successful/contact-successful.component';
import { JobListingsComponent } from './job-listings/job-listings.component';


@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    HomeComponent,
    FooterComponent,
    OutSourcingComponent,
    FirstAidComponent,
    MvpComponent,
    ContactComponent,
    HeaderComponent,
    ContactSuccessfulComponent,
    JobListingsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
