<section class="contact-successful-container other-nav-container">
    <section class="container">
        <p class="presentation">
            <span class="item-color">Thank you!</span>
        </p><br>
        <p class="presentation">    
            Lorem ipsum dolor sit amet, consectetur
            adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur
        </p>
        <input type="submit" class="home-btn" value="Home" (click)="goTo()">
    </section>
    <svg class="svg-separator separator-up curveDown curveDownMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
    </svg>
</section>
<app-footer></app-footer>