import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactSuccessfulComponent } from './contact-successful/contact-successful.component';
import { ContactComponent } from './contact/contact.component';
import { FirstAidComponent } from './first-aid/first-aid.component';
import { HomeComponent } from './home/home.component';
import { JobListingsComponent } from './job-listings/job-listings.component';
import { MvpComponent } from './mvp/mvp.component';
import { OutSourcingComponent } from './out-sourcing/out-sourcing.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'mvp', component: MvpComponent },
  { path: 'out-sourcing', component: OutSourcingComponent },
  { path: 'first-aid', component: FirstAidComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'contact-successful', component: ContactSuccessfulComponent },
  { path: 'jobs', component: JobListingsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
