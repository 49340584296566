<app-header [titleHeader]="titleHeader" [presentationHeader]="presentationHeader"></app-header>

<section class="out-sourcing-container global-article-presentation">
    <section class="container">
        <article class="column-article-presentation">
            <div class="icon-section">
                <div class="icon">
                    <i class="fa fa-2x fa-black-tie fas"></i>
                </div>
                <div class="title-section">Front-End Expertise is our jam</div>
                <div class="underline"></div>
            </div>
            <div class="content-section">
                If your team is busy building out the underlying technology that will make you succeed, bring us in to
                build all the shiny chrome on top. We can make the cool exciting interface that draws everyone in to
                experience the revolution your novel technology is going to bring.
            </div>
        </article>
        <article class="column-article-presentation">
            <div class="content-section">
                Is there some new third-party API you'd like to integrate into your product offering, but can't spare
                the resources to adapt your APIs to accommodate? We can come in and build abstraction glue that acts as
                a middle man to make the match.
            </div>
            <div class="icon-section">
                <div class="icon">
                    <i class="fa-2x fa-plug fas"></i>
                </div>
                <div class="title-section">API Glue</div>
                <div class="underline"></div>
            </div>
        </article>
        <div class="underline"></div>
        <article class="column-article-presentation">
            <div class="icon-section">
                <div class="icon">
                    <i class="fa fa-2x fa-gem fas"></i>
                </div>
                <div class="title-section">Halo components</div>
                <div class="underline"></div>
            </div>
            <div class="content-section">
                Not all components are created equal. Sometimes you want an attention-grabbing unique halo-component
                that adds some spice to your app. They're especially useful if you need your users to consistently
                perform an otherwise boring task.
            </div>
        </article>
        <article class="column-article-presentation">
            <div class="content-section">
                Sometimes you need to bring in some ringers to handle updating your tech to the latest versions whether
                it's adapting to new APIs, getting the latest security patches or moving off retired/legacy frameworks
                and platforms. We can step in and take the weight of your dev team so they can keep executing where your
                long term strategy.
            </div>
            <div class="icon-section">
                <div class="icon">
                    <i class="fa-2x fa-users-cog fas"></i>
                    <div class="title-section">Refactoring, Upgrading, Transitions</div>
                    <div class="underline"></div>
                </div>
            </div>
        </article>
    </section>
    <svg class="svg-separator separator-up curveDown curveDownMainPage" xmlns="http://www.w3.org/2000/svg" version="1.1"
        width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 0 C 50 100 80 100 100 0 Z"></path>
    </svg>
</section>
<app-footer></app-footer>