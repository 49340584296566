<header class="global-header-container">
    <section class="container global-header-presentation">
        <div class="header-presentation">
            <h1 class="title section-title">{{titleHeader}}</h1>
            <div class="border-separation"></div>
            <div class="under-border-separation"></div>
            <h5 *ngIf="subtitleHeader">{{subtitleHeader}}</h5>
            <p class="presentation" [innerHTML]="presentationHeader"></p>
            <svg viewBox="0 0 100 20" class="header-line-only">
                <path d="M 0 20 L 90 20 Q 100 20 100 10 L 100 0">
                </path>
                <defs>
                    <linearGradient id="orange-to-darkOrange" x1="1" x2="0" y1="1" y2="0">
                        <stop offset="0%" stop-color="#264598"></stop>
                        <stop offset="100%" stop-color="#264598"></stop>
                    </linearGradient>
                </defs>
            </svg>
        </div>
    </section>
</header>
