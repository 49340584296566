<nav class="top-nav-container" id="topNavContainer">
    <div class="logo" [routerLink]="['/home']" (click)="statusTopNavLink = -1">
        <img src="../../assets/img/ps_logo.png" alt="" width="100px">
    </div>
    <ul class="top-nav-links with-indicator">
        <li class="top-nav-link" id="{{link.id}}" *ngFor="let link of topNavLinks; let i = index"
            (click)="statusTopNavLink = i ; goTo(link.url, $event)" 
            (mouseover)="link[i] = true" 
            (mouseleave)="link[i] = false"
            [class.top-nav-link-cliked]="statusTopNavLink == i">
            <i class="{{link.fa}}"></i>
            <a>{{link.name}}</a>
        </li>
    </ul>
    <div class="progress-on-scroll-container">
        <div class="progress-bar" id="progressBar"></div>
    </div>
</nav>