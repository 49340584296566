import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mvp',
  templateUrl: './mvp.component.html',
  styleUrls: ['./mvp.component.scss']
})
export class MvpComponent implements OnInit {
  @Input() public titleHeader: string = 'Build Your App';
  @Input() public presentationHeader: string = "We are here to help you get from project inception all the way through to your very own App success story. Here's a brief walkthrough of what's involved and how we make the process smooth and success-focused. Each stage can be as involved as it needs to be, or focused and prioritized on what values are most important to you. Sometimes time-to-market is critical. Sometimes you want to take the time to get the idea just so. We follow your lead and help you to make the best decisions for your company.";
  
  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
