import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NAVBAR } from '../mock-info/nav-bar'

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  public topNavLinks = NAVBAR;
  public statusTopNavLink = -1;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  goTo(url: string, section) {
    this.router.navigate(['/' + url]);
  }

}
